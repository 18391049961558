import type { FeatureCollection, Point } from "geojson";
import { useEffect, useState } from "react";
import useAuthAccessToken from "../../../../hooks/useAuthAccessToken";
import dedupeArray from "../../../../utils/dedupeArray/dedupeArray";
import useUnsafeMapContext from "../../Map/useUnsafeMapContext";
import { getMaxAllowableOffset } from "../../utils/getMaxAllowableOffset";
import { useFIFOCache } from "../useFIFOCache/useFIFOCache";
import { useTiles } from "../useTiles/useTiles";

// Used as a type, so disabling this lint error for now
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ZOOM_LEVEL_METERS_PER_PIXEL_AT_LATITUDE_40 = {
  0: 59959.436,
  1: 29929.718,
  2: 14964.859,
  3: 7482.929,
  4: 3741.964,
  5: 1873.732,
  6: 936.866,
  7: 468.433,
  8: 234.217,
  9: 117.108,
  10: 58.554,
  11: 29.277,
  12: 14.638,
  13: 7.319,
  14: 3.659,
  15: 1.879,
  16: 0.915,
  17: 0.457,
  18: 0.229,
  19: 0.114,
  20: 0.057,
  21: 0.029,
  22: 0.014,
};

type ZoomLevels = keyof typeof ZOOM_LEVEL_METERS_PER_PIXEL_AT_LATITUDE_40;

export const defaultParams = {
  where: "1=1",
  geometryType: "esriGeometryEnvelope",
  inSR: "4326",
  outSR: "4326",
  spatialRel: "esriSpatialRelIntersects",
  returnGeometry: "true",
  returnTrueCurves: "false",
  returnIdsOnly: "false",
  returnCountOnly: "false",
  returnZ: "false",
  returnM: "false",
  returnDistinctValues: "false",
  returnExtentOnly: "false",
  featureEncoding: "esriDefault",
  units: "esriSRUnit_Meter",
  f: "geojson",
};

export type QueryOptions = {
  cacheSize?: number; // cache size of 0 indicates a disabled cache
  queryParams?: Record<string, unknown>;
  minZoom?: ZoomLevels;
};

export const useMapServerQueryData = (
  baseUrl: string,
  options?: QueryOptions,
) => {
  const [refetchKey, setRefetchKey] = useState(Math.random());
  const { map } = useUnsafeMapContext();
  const [featureCollection, setFeatureCollection] =
    useState<FeatureCollection | null>(null);
  const [error, setError] = useState(null);
  const tileBboxes = useTiles();
  const mapCache = useFIFOCache<FeatureCollection<Point>>(options?.cacheSize);
  const accessToken = useAuthAccessToken();
  const cacheEnabled = options?.cacheSize !== 0;
  useEffect(() => {
    if (!map) return;
    if (
      !tileBboxes.length ||
      (options?.minZoom && map.getZoom() < options.minZoom)
    ) {
      setFeatureCollection(null);
      return;
    }
    /*
     * Set up abort controllers for each of the tile queries to
     * cancel requests if the user continues to move the map.
     */
    const controllers = tileBboxes.map(() => new AbortController());
    const promises = tileBboxes.map(({ bbox, tile }, i) => {
      const tileKey = JSON.stringify(tile);
      const cachedData = mapCache.get(tileKey);
      if (cachedData && cacheEnabled) {
        // Serve from cache if data already exists
        return Promise.resolve<FeatureCollection<Point>>(cachedData);
      }

      const query = {
        ...defaultParams,
        ...(options?.queryParams ?? {}),
        geometry: bbox.toString(),
        maxAllowableOffset: getMaxAllowableOffset(map).toString(),
      };
      const queryString = new URLSearchParams(query).toString();
      const url = `${baseUrl}?${queryString}`;

      const controller = controllers[i];
      const { signal } = controller;
      // Apply auth token if getting data from the athena proxy
      const headers = baseUrl.includes("shared-athena")
        ? { Authorization: `Bearer ${accessToken}` }
        : undefined;

      return fetch(url, {
        signal,
        headers,
      })
        .then((res) => res.json())
        .then((data: FeatureCollection<Point>) => {
          if (cacheEnabled) {
            mapCache.set(tileKey, data);
          }
          return data;
        });
    });
    Promise.all(promises)
      .then((results) => {
        // merge all results into a single feature collection
        const allFeatures = results.map(({ features }) => features).flat();
        setFeatureCollection({
          type: "FeatureCollection",
          features: dedupeArray(allFeatures),
        });
      })
      .catch((err) => {
        setError(err);
      });

    return () => {
      // cancel all inflight requests on cleanup
      controllers.forEach((controller) => controller.abort());
    };
  }, [
    baseUrl,
    tileBboxes,
    options,
    accessToken,
    mapCache,
    map,
    cacheEnabled,
    refetchKey,
  ]);

  const refetch = () => {
    setRefetchKey(Math.random());
  };

  return { data: featureCollection, error, refetch };
};
