/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type { GetBrigades200, GetBrigadesParams } from "./types";
import type { JSONAPIErrorResponse } from "./types/jsonapi.yml";

/**
 * Gets a list of RFS brigades along with their current coverage snapshots.

 * @summary Get brigades
 */
export const getBrigades = (
  params?: GetBrigadesParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetBrigades200>> => {
  return axios.get(`/brigades`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetBrigadesQueryKey = (params?: GetBrigadesParams) => {
  return [`/brigades`, ...(params ? [params] : [])] as const;
};

export const getGetBrigadesQueryOptions = <
  TData = Awaited<ReturnType<typeof getBrigades>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetBrigadesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getBrigades>>, TError, TData>
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBrigadesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBrigades>>> = ({
    signal,
  }) => getBrigades(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getBrigades>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetBrigadesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBrigades>>
>;
export type GetBrigadesQueryError = AxiosError<JSONAPIErrorResponse>;

export function useGetBrigades<
  TData = Awaited<ReturnType<typeof getBrigades>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params: undefined | GetBrigadesParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getBrigades>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getBrigades>>,
          TError,
          TData
        >,
        "initialData"
      >;
    axios?: AxiosRequestConfig;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetBrigades<
  TData = Awaited<ReturnType<typeof getBrigades>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetBrigadesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getBrigades>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getBrigades>>,
          TError,
          TData
        >,
        "initialData"
      >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetBrigades<
  TData = Awaited<ReturnType<typeof getBrigades>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetBrigadesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getBrigades>>, TError, TData>
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get brigades
 */

export function useGetBrigades<
  TData = Awaited<ReturnType<typeof getBrigades>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetBrigadesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getBrigades>>, TError, TData>
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetBrigadesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
