import {
  mobileCoverageOptusLayer,
  mobileCoverageTelstraLayer,
  mobileCoverageTpgLayer,
} from "../../../config/layers/layers";

export const mobileCoverageLayers = [
  mobileCoverageOptusLayer,
  mobileCoverageTelstraLayer,
  mobileCoverageTpgLayer,
] as const;

export const mobileCoverageSubLayerIds = mobileCoverageLayers.flatMap(
  ({ subLayers }) => subLayers.map(({ id }) => id),
);

export type MobileCoverageSubLayerIdType =
  (typeof mobileCoverageSubLayerIds)[number];

// Ids for each of these layers: https://gis-maps.dev.apps.rfs.nsw.gov.au/arcgis/rest/services/Reference/AthenaMobileInfrastructure/MapServer
export const mobileCoverageGisLayers = {
  mobileCoverageOptusSitesLayer: [2],
  mobileCoverageOptusCoverageLayer: [6],
  mobileCoverageTelstraSitesLayer: [3],
  mobileCoverageTelstraCoverageLayer: [7],
  mobileCoverageTpgSitesLayer: [1],
  mobileCoverageTpgCoverageLayer: [5],
} as const satisfies Record<MobileCoverageSubLayerIdType, readonly number[]>;
