import { useEffect } from "react";
import { useGetSourcesLivetrafficIncidents } from "../../../../.rest-hooks/sources";
import { MapLevel } from "../../../config/layers/layers";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import RtaIncidentPopup from "../../popup/RtaIncidentPopup/RtaIncidentPopup";
import useMapContext from "../Map/useMapContext";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import { isGeoJsonSource } from "../types";
import { loadImage } from "../utils/loadImage";
import {
  type RtaIncidentFeatureProperties,
  getPropertiesFromFeature,
} from "./interactions";

const RTA_INCIDENTS_ID = "rtaIncidents";

const RtaIncidentsLayer = () => {
  const map = useMapContext();
  const { data } = useGetSourcesLivetrafficIncidents({
    query: {
      select: (response) =>
        response.data.data.attributes
          .geojson as unknown as GeoJSON.FeatureCollection<
          GeoJSON.Point,
          RtaIncidentFeatureProperties
        >,
    },
  });

  useEffect(() => {
    const controller = new AbortController();

    map.addSource(RTA_INCIDENTS_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    loadImage({
      imageId: RTA_INCIDENTS_ID,
      map,
      src: "/icons/rta-incident.png",
      signal: controller.signal,
    }).then(() => {
      map.addLayer(
        {
          id: RTA_INCIDENTS_ID,
          type: "symbol",
          source: RTA_INCIDENTS_ID,
          layout: {
            "icon-image": RTA_INCIDENTS_ID,
            "icon-allow-overlap": true,
            "icon-optional": false,
          },
        },
        MapLevel.SYMBOLS,
      );
    }, catchAbortError);

    return () => {
      controller.abort();
      if (map.getLayer(RTA_INCIDENTS_ID)) {
        map.removeLayer(RTA_INCIDENTS_ID);
      }
      if (map.hasImage(RTA_INCIDENTS_ID)) {
        map.removeImage(RTA_INCIDENTS_ID);
      }
      if (map.getSource(RTA_INCIDENTS_ID)) {
        map.removeSource(RTA_INCIDENTS_ID);
      }
    };
  }, [map]);

  useEffect(() => {
    const source = map.getSource(RTA_INCIDENTS_ID);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, map]);

  const { clickedState, deactivateClickState } = useLayerInteractions({
    getPropertiesFromFeature,
    layerId: RTA_INCIDENTS_ID,
  });

  return (
    <RtaIncidentPopup
      onClose={deactivateClickState}
      state={clickedState}
      type="click"
    />
  );
};

export default RtaIncidentsLayer;
