import { mobileCoverageTelstraLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import MobileCoverageLayer from "./MobileCoverageLayer";

const MobileCoverageTelstra = () => {
  const { isLayerActive, getLayerStateFromConfig } = useActiveLayersContext();

  if (!isLayerActive(mobileCoverageTelstraLayer.id)) {
    return null;
  }

  const state = getLayerStateFromConfig(mobileCoverageTelstraLayer);

  return (
    <>
      {mobileCoverageTelstraLayer.subLayers.map(({ id }) =>
        state?.[id]?.isActive ? (
          <MobileCoverageLayer
            key={id}
            opacity={state?.opacity}
            subLayerId={id}
          />
        ) : null,
      )}
    </>
  );
};

export default MobileCoverageTelstra;
