import getMapServerProxyBasepath from "../../../../utils/getMapServerProxyBasepath";

const maxPages = 5000;
const perPage = 1000;
const batchSize = 4;

type LightningResult = {
  features: LightningFeature[];
};

type LightningFeature = {
  attributes: LightningAttributes;
};

type LightningAttributes = {
  ID: number;
  TimeStp: number;
  Lon: number;
  Lat: number;
  Amp: number;
  Type: number;
};

const MapUrl = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/Athena/MapServer/6/query`;

export const formatFileDate = (d: Date): string => {
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const day = d.getDate();
  const hour = `${d.getHours()}`.padStart(2, "0");
  const min = `${d.getMinutes()}`.padStart(2, "0");
  return `${year}-${month}-${day} ${hour}${min}`;
};

const formatQueryDate = (d: Date): string => {
  const year = d.getUTCFullYear();
  const month = `${d.getUTCMonth() + 1}`.padStart(2, "0");
  const day = `${d.getUTCDate()}`.padStart(2, "0");
  const hours = `${d.getUTCHours()}`.padStart(2, "0");
  const minutes = `${d.getUTCMinutes()}`.padStart(2, "0");
  const seconds = `${d.getUTCSeconds()}`.padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const formatCsvDate = (d: Date, utc: boolean): string => {
  const year = utc ? d.getUTCFullYear() : d.getFullYear();
  const mth = (utc ? d.getUTCMonth() : d.getMonth()) + 1;
  const dy = utc ? d.getUTCDate() : d.getDate();
  const hr = utc ? d.getUTCHours() : d.getHours();
  const min = utc ? d.getUTCMinutes() : d.getMinutes();
  const sec = utc ? d.getUTCSeconds() : d.getSeconds();
  const ampm = hr >= 12 ? "PM" : "AM";
  const hrMod = hr % 12;
  const hr12 = hrMod === 0 ? 12 : hrMod;
  const day = `${dy}`.padStart(2, "0");
  const month = `${mth}`.padStart(2, "0");
  const hours = `${hr12}`.padStart(2, "0");
  const minutes = `${min}`.padStart(2, "0");
  const seconds = `${sec}`.padStart(2, "0");
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
};

const getLightningPage = async ({
  from,
  to,
  records,
  offset,
  accessToken,
}: {
  from: Date;
  to: Date;
  records: number;
  offset: number;
  accessToken: string;
}): Promise<LightningResult> => {
  const fromStr = formatQueryDate(from);
  const toStr = formatQueryDate(to);
  const queryString = new URLSearchParams({
    where: `TimeStp BETWEEN DATE '${fromStr}' AND DATE '${toStr}'`,
    f: "pjson",
    outfields: "*",
    resultRecordCount: `${records}`,
    resultOffset: `${offset}`,
  }).toString();
  const res = await fetch(`${MapUrl}?${queryString}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data = await res.json();
  return data as LightningResult;
};

export const getLightningData = async ({
  from,
  to,
  accessToken,
}: {
  from: Date;
  to: Date;
  accessToken: string;
}): Promise<string> => {
  let result: LightningFeature[] = [];

  // check page count doesn't go over limit
  const testResp = await getLightningPage({
    from,
    to,
    records: 1,
    offset: perPage * maxPages,
    accessToken,
  });
  if (testResp.features.length > 0) {
    throw Error("request too large");
  }

  for (let i = 0; i < maxPages; i += batchSize) {
    const pages: number[] = [];
    for (let j = 0; j < batchSize; j += 1) {
      pages.push(j + i);
    }

    const responses = await Promise.all(
      pages.map((page) =>
        getLightningPage({
          from,
          to,
          records: perPage,
          offset: perPage * page,
          accessToken,
        }),
      ),
    );

    const features = responses.flatMap((r) => r.features);
    result = result.concat(features);
    if (features.length === 0) {
      break;
    }
  }

  return buildCsv(result);
};

const buildCsv = (strikes: LightningFeature[]): string => {
  const csv: string[] = [];

  csv.push(
    "Id,StrokeDateTime,Longitude,Latitude,Amplitude,StrokeType,UTCDate\n",
  );
  strikes.forEach((f) => {
    const d = new Date(f.attributes.TimeStp);
    csv.push(
      `${f.attributes.ID},`,
      `${formatCsvDate(d, false)},`,
      `${f.attributes.Lon},`,
      `${f.attributes.Lat},`,
      `${f.attributes.Amp},`,
      `${f.attributes.Type},`,
      `${formatCsvDate(d, true)}\n`,
    );
  });

  return csv.join("");
};
