/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  GetSourcesActAvl200,
  GetSourcesLivetrafficIncidents200,
} from "./types";

/**
 * Get ACT AVL data.

AVL data is ingested from: https://www.esacop.act.gov.au/rfs/DATA/ACT_VEHICLE.js

 * @summary Get ACT AVL data
 */
export const getSourcesActAvl = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetSourcesActAvl200>> => {
  return axios.get(`/sources/act-avl`, options);
};

export const getGetSourcesActAvlQueryKey = () => {
  return [`/sources/act-avl`] as const;
};

export const getGetSourcesActAvlQueryOptions = <
  TData = Awaited<ReturnType<typeof getSourcesActAvl>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSourcesActAvl>>, TError, TData>
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSourcesActAvlQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSourcesActAvl>>
  > = ({ signal }) => getSourcesActAvl({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSourcesActAvl>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetSourcesActAvlQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSourcesActAvl>>
>;
export type GetSourcesActAvlQueryError = AxiosError<unknown>;

export function useGetSourcesActAvl<
  TData = Awaited<ReturnType<typeof getSourcesActAvl>>,
  TError = AxiosError<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSourcesActAvl>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSourcesActAvl>>,
        TError,
        TData
      >,
      "initialData"
    >;
  axios?: AxiosRequestConfig;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetSourcesActAvl<
  TData = Awaited<ReturnType<typeof getSourcesActAvl>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSourcesActAvl>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSourcesActAvl>>,
        TError,
        TData
      >,
      "initialData"
    >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSourcesActAvl<
  TData = Awaited<ReturnType<typeof getSourcesActAvl>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSourcesActAvl>>, TError, TData>
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get ACT AVL data
 */

export function useGetSourcesActAvl<
  TData = Awaited<ReturnType<typeof getSourcesActAvl>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSourcesActAvl>>, TError, TData>
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetSourcesActAvlQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get live traffic incidents.

Incident data is ingested from: https://www.livetraffic.com/traffic/hazards/incident.json

 * @summary Get traffic incidents
 */
export const getSourcesLivetrafficIncidents = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetSourcesLivetrafficIncidents200>> => {
  return axios.get(`/sources/livetraffic-incidents`, options);
};

export const getGetSourcesLivetrafficIncidentsQueryKey = () => {
  return [`/sources/livetraffic-incidents`] as const;
};

export const getGetSourcesLivetrafficIncidentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSourcesLivetrafficIncidentsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>
  > = ({ signal }) =>
    getSourcesLivetrafficIncidents({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetSourcesLivetrafficIncidentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>
>;
export type GetSourcesLivetrafficIncidentsQueryError = AxiosError<unknown>;

export function useGetSourcesLivetrafficIncidents<
  TData = Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>,
  TError = AxiosError<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>,
        TError,
        TData
      >,
      "initialData"
    >;
  axios?: AxiosRequestConfig;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetSourcesLivetrafficIncidents<
  TData = Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>,
        TError,
        TData
      >,
      "initialData"
    >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSourcesLivetrafficIncidents<
  TData = Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get traffic incidents
 */

export function useGetSourcesLivetrafficIncidents<
  TData = Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetSourcesLivetrafficIncidentsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
