import { onPromise } from "@app/design-system";
import { useMutation } from "@tanstack/react-query";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import { useFormContext, type SubmitHandler } from "react-hook-form";
import useAuthAccessToken from "../../../../hooks/useAuthAccessToken";
import { coordinateParseFunctions } from "../../../../utils/coordinateConversion";
import getMapServerProxyBasepath from "../../../../utils/getMapServerProxyBasepath";
import type { AVLData } from "../../../map/AVLPositions/interactions";
import SearchDrawerForm, {
  type SearchDrawerFormError,
  type SearchDrawerFormValues,
  type SearchDrawerResult,
} from "./SearchDrawerForm";

export const VEHICLE_LOCATION_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/VehicleLocations/MapServer/1/query`;

interface GetBfNumberLocationParams {
  authAccessToken: string | undefined;
  bfNumber: string;
}

export interface BfNumberFeature {
  attributes: {
    Longitude: number;
    Latitude: number;
    BfNumber: string;
  } & AVLData;
}

export interface BfNumberResult {
  features: BfNumberFeature[];
}

const getBfNumberLocation = async ({
  authAccessToken,
  bfNumber,
}: GetBfNumberLocationParams): Promise<BfNumberResult> => {
  const searchField = bfNumber.toLowerCase().startsWith("bf")
    ? "BfNumber"
    : "CallSign";
  const queryString = new URLSearchParams({
    f: "pjson",
    where: `(${searchField} LIKE '%${bfNumber}%')`,
    returnGeometry: "false",
    spatialRel: "esriSpatialRelIntersects",
    outFields:
      "BfNumber,Brigade,CallSign,Category,Description,DeviceType,Emergency,GpsStatus,LicenseNo,RadioId,ReportAge,Status,DirectionHor,StatusCategory,TalkGroupId,Longitude,Latitude",
  }).toString();
  const res = await fetch(`${VEHICLE_LOCATION_URL}?${queryString}`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `Bearer ${authAccessToken}`,
    },
  });
  const data = await res.json();
  return data as BfNumberResult;
};

interface SearchDrawerViewProps {
  formError: SearchDrawerFormError | null;
  onComplete: (result: SearchDrawerResult) => void;
  onError: (formError: SearchDrawerFormError | null) => void;
}

const SearchDrawerView = ({
  formError,
  onComplete,
  onError,
}: SearchDrawerViewProps) => {
  const authAccessToken = useAuthAccessToken();

  const { handleSubmit } = useFormContext<SearchDrawerFormValues>();

  const { mutateAsync: mutateBfNumber } = useMutation({
    mutationFn: getBfNumberLocation,
  });

  const onSubmit: SubmitHandler<SearchDrawerFormValues> = async (values) => {
    onError(null);

    switch (values.searchMode) {
      case "locationCoordinates": {
        const coords = coordinateParseFunctions[
          values.locationCoordinates.type
        ].conversion(values.locationCoordinates.value);

        if (!coords) {
          onError("invalidCoordinatesError");
        } else {
          onComplete({ type: "coordinates", value: coords });
        }
        break;
      }
      case "locationName":
        break;
      case "bfNumber":
        try {
          const data = await mutateBfNumber({
            authAccessToken,
            bfNumber: values.bfNumber,
          });

          if (isEmpty(data.features)) {
            onError(
              values.bfNumber.toLowerCase().startsWith("bf")
                ? "noBfNumberResultsError"
                : "noCallSignResultsError",
            );
          } else {
            const firstFeature = first(data.features)!;

            onComplete({
              type: "resource",
              value: {
                coordinates: {
                  lat: firstFeature.attributes.Latitude,
                  lng: firstFeature.attributes.Longitude,
                },
                resource: firstFeature,
              },
            });
          }
        } catch {
          onError("bfNumberError");
        }
        break;
    }
  };

  return (
    <SearchDrawerForm
      formError={formError}
      id="searchDrawerForm"
      onSubmit={onPromise(handleSubmit(onSubmit))}
      onComplete={onComplete}
    />
  );
};

export default SearchDrawerView;
