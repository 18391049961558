import { publicSafetyNetworkLayer } from "../../../config/layers/layers";

export const publicSafetyNetworkSubLayerIdTypes =
  publicSafetyNetworkLayer.subLayers.map(({ id }) => id);

export type PublicSafetyNetworkSubLayerIdType =
  (typeof publicSafetyNetworkSubLayerIdTypes)[number];

// Ids for each of these layers: https://gis-maps.dev.apps.rfs.nsw.gov.au/arcgis/rest/services/Reference/AthenaMobileInfrastructure/MapServer
export const publicSafetyNetworkGisLayers = {
  publicSafetyNetworkSitesLayer: [0],
  publicSafetyNetworkCoverageLayer: [4],
} as const satisfies Record<
  PublicSafetyNetworkSubLayerIdType,
  readonly number[]
>;
