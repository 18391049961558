import { useEventListener } from "usehooks-ts";

const useEscapeKeyDown = (onEscapeKeyDown: (event: KeyboardEvent) => void) => {
  const onKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case "Esc":
      case "Escape": {
        return onEscapeKeyDown(event);
      }
      default:
    }
  };

  useEventListener("keydown", onKeyDown);
};

export default useEscapeKeyDown;
