import { mobileCoverageOptusLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import MobileCoverageLayer from "./MobileCoverageLayer";

const MobileCoverageOptus = () => {
  const { isLayerActive, getLayerStateFromConfig } = useActiveLayersContext();

  if (!isLayerActive(mobileCoverageOptusLayer.id)) {
    return null;
  }

  const state = getLayerStateFromConfig(mobileCoverageOptusLayer);

  return (
    <>
      {mobileCoverageOptusLayer.subLayers.map(({ id }) =>
        state?.[id]?.isActive ? (
          <MobileCoverageLayer
            key={id}
            opacity={state?.opacity}
            subLayerId={id}
          />
        ) : null,
      )}
    </>
  );
};

export default MobileCoverageOptus;
