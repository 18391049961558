import {
  Alert,
  Button,
  DEFAULT_ERROR_MESSAGE,
  Download,
  ModalForm,
  onPromise,
} from "@app/design-system";
import { useEffect, useState } from "react";
import { FormProvider, useForm, type SubmitHandler } from "react-hook-form";
import { useBoolean } from "usehooks-ts";
import useAuthAccessToken from "../../../../hooks/useAuthAccessToken";
import downloadBlobAsFile from "../../../../utils/downloadBlobAsFile";
import { formatFileDate, getLightningData } from "./LightningData";
import LightningDownloadForm, {
  getDefaultLightningDownloadFormValues,
  type LightningDownloadFormValues,
} from "./LightningDownloadForm";

type FormError = "downloadError" | "requestError";

const DownloadLightningDataButton = () => {
  const { value: isOpen, setTrue: open, setFalse: close } = useBoolean();

  const accessToken = useAuthAccessToken();

  const [isError, setIsError] = useState<FormError | null>(null);

  const form = useForm<LightningDownloadFormValues>({
    defaultValues: getDefaultLightningDownloadFormValues(),
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    watch,
  } = form;

  const queryType = watch("queryType");

  useEffect(() => {
    setIsError(null);
  }, [queryType]);

  const onSubmit: SubmitHandler<LightningDownloadFormValues> = async (
    values,
  ) => {
    setIsError(null);
    try {
      if (values.queryType === "range") {
        const toDate = new Date(`${values.intervalTo}T00:00:00.000`);
        const fromDate = new Date(`${values.intervalFrom}T00:00:00.000`);
        const response = await getLightningData({
          to: toDate,
          from: fromDate,
          accessToken: accessToken || "",
        });
        const blob = new Blob([response]);
        close();
        const fromStr = formatFileDate(fromDate);
        const toStr = formatFileDate(toDate);
        const filename = `LightningData from ${fromStr} to ${toStr}.csv`;
        return downloadBlobAsFile({ blob, filename });
      }
      if (values.queryType === "period") {
        const now = Date.now();
        const timePeriod = Number(values.timePeriod);
        const from = now - timePeriod * 60 * 60 * 1000; // time in seconds
        const response = await getLightningData({
          to: new Date(),
          from: new Date(from),
          accessToken: accessToken || "",
        });
        const blob = new Blob([response]);
        close();
        const fromStr = formatFileDate(new Date(from * 1000));
        const toStr = formatFileDate(new Date());
        const filename = `LightningData from ${fromStr} to ${toStr}.csv`;
        return downloadBlobAsFile({ blob, filename });
      }
    } catch (error) {
      if (
        (error as { response?: { status: number } }).response?.status === 400
      ) {
        setIsError("requestError");
      } else {
        setIsError("downloadError");
      }
    }
  };

  return (
    <>
      <Button icon={Download} onClick={open} variant="secondary">
        Download lightning data
      </Button>
      <ModalForm
        error={
          <>
            {isError === "downloadError" && (
              <Alert variant="error" title="Unable to submit response">
                {DEFAULT_ERROR_MESSAGE}
              </Alert>
            )}
            {isError === "requestError" && (
              <Alert variant="error" title="Bad Request">
                Dates need to be less than 14 days ago.
              </Alert>
            )}
          </>
        }
        isOpen={isOpen}
        isSubmitting={isSubmitting}
        isDismissable
        isKeyboardDismissDisabled={false}
        onCancel={close}
        onSubmit={onPromise(handleSubmit(onSubmit))}
        id="downloadLightningData"
        submitLabel="Download"
        title="Download lightning data"
      >
        <FormProvider {...form}>
          <LightningDownloadForm />
        </FormProvider>
      </ModalForm>
    </>
  );
};

export default DownloadLightningDataButton;
