import { publicSafetyNetworkLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import PublicSafetyNetworkLayer from "./PublicSafetyNetworkLayer";
import { publicSafetyNetworkSubLayerIdTypes } from "./utils";

const PublicSafetyNetwork = () => {
  const { isLayerActive, getLayerStateFromConfig } = useActiveLayersContext();

  if (!isLayerActive(publicSafetyNetworkLayer.id)) {
    return null;
  }

  const state = getLayerStateFromConfig(publicSafetyNetworkLayer);

  return (
    <>
      {publicSafetyNetworkSubLayerIdTypes.map((id) =>
        state?.[id]?.isActive ? (
          <PublicSafetyNetworkLayer
            key={id}
            opacity={state?.opacity}
            subLayerId={id}
          />
        ) : null,
      )}
    </>
  );
};

export default PublicSafetyNetwork;
