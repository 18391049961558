import { Divider, FieldGrid, Text } from "@app/design-system";
import styled from "styled-components";
import FallbackElement from "../../../utils/fallBackElement/fallBackElement";
import { getFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import type { ACTESAResourcesInteractionProperties } from "../../map/ACTESAResources/interactions";

const StyledBody = styled.div`
  display: grid;
  gap: 0.5rem;
`;

const StyledContent = styled.div`
  display: grid;
  gap: 0.75rem;
`;

export interface ACTESAResourcesPopupDetailProps {
  properties: ACTESAResourcesInteractionProperties;
}

const ACTESAResourcesPopupDetail = ({
  properties,
}: ACTESAResourcesPopupDetailProps) => {
  return (
    <StyledBody>
      <Text size="subtitleMd">{properties.data.VCS}</Text>
      <StyledContent>
        <FieldGrid>
          <FieldGrid.Item label="Agency:">
            {properties.data.AGC || <FallbackElement />}
          </FieldGrid.Item>
          <FieldGrid.Item label="Vehicle type:">
            {properties.data.VGP || <FallbackElement />}
          </FieldGrid.Item>
          <FieldGrid.Item label="Secondary vehicle type:">
            {properties.data.VTP || <FallbackElement />}
          </FieldGrid.Item>
          <FieldGrid.Item label="Last GPS timestamp:">
            {properties.data.TIM ? (
              getFormattedDateAndTime(parseInt(properties.data.TIM, 10))
            ) : (
              <FallbackElement />
            )}
          </FieldGrid.Item>
          <FieldGrid.Item label="Long, lat:">
            {/* eslint-disable-next-line @typescript-eslint/no-base-to-string */}
            {properties.lngLat.toLocaleString()}
          </FieldGrid.Item>
        </FieldGrid>
        <Divider />
        <FieldGrid>
          <FieldGrid.Item fullWidth label="Assigned to:">
            {properties.data.INO || properties.data.ITP ? (
              `${properties.data.INO || "—"},${" "}
              ${properties.data.ITP || "—"}`
            ) : (
              <FallbackElement />
            )}
          </FieldGrid.Item>
        </FieldGrid>
      </StyledContent>
    </StyledBody>
  );
};

export default ACTESAResourcesPopupDetail;
