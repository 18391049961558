import { Accordion } from "@app/design-system";
import isEmpty from "lodash/isEmpty";
import { useState } from "react";
import styled from "styled-components";
import { useGetPermissions } from "../../../../.rest-hooks/permissions";
import {
  isLayerWithSubLayers,
  type LayerConfig,
} from "../../../config/layers/layers";
import LabelWithCount from "../../ui/LabelWithCount/LabelWithCount";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import LayerCheckbox from "./LayerCheckbox";
import LayerGroupCheckboxList from "./LayerGroupCheckboxList";

const StyledLayerList = styled.div`
  display: grid;
  gap: 0.5rem;
  padding: 0.75rem;
`;

interface LayerListAccordionProps {
  layers: LayerConfig[];
  title: string;
  "data-testid"?: string;
}

const LayerListAccordion = ({
  layers,
  title,
  "data-testid": dataTestId,
}: LayerListAccordionProps) => {
  const { data } = useGetPermissions();
  const userPermissions = data?.data.meta.permissions;

  const { isLayerActive } = useActiveLayersContext();

  const count = layers.filter((layer) => isLayerActive(layer.id)).length;

  const [isExpanded, setIsExpanded] = useState(() => !!count);

  const filteredLayers = layers.filter((layer) => {
    if (!layer.requestedPermissions) return true;

    return layer.requestedPermissions.every((permission) =>
      userPermissions?.includes(permission),
    );
  });

  if (isEmpty(filteredLayers)) {
    return;
  }

  return (
    <Accordion
      isExpanded={isExpanded}
      onExpandedChange={(nextIsExpanded) => setIsExpanded(nextIsExpanded)}
      title={<LabelWithCount count={count}>{title}</LabelWithCount>}
      data-testid={dataTestId}
    >
      <StyledLayerList>
        {filteredLayers.map((layer) => {
          return isLayerWithSubLayers(layer) ? (
            <LayerGroupCheckboxList key={layer.id} layer={layer} />
          ) : (
            <LayerCheckbox
              key={layer.id}
              isVisible={isExpanded}
              layer={layer}
            />
          );
        })}
      </StyledLayerList>
    </Accordion>
  );
};

export default LayerListAccordion;
